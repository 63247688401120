import type {
  Analytics,
  AnalyticsBrowser as AnalyticsBrowserClass,
  Options,
  SegmentEvent,
} from '@segment/analytics-next'
import { setContext } from '@sentry/nextjs'
import { User } from 'src/types'

import { isPreviewEnv, isProductionEnv } from '../helpers'
import { PageTrackerOptions } from './hooks/types'
import { PageEvents, PageName, PageProperties } from './pages'
import { publishToHost } from './publishToHost'

const analyticsCdnUrl =
  'https://cdn.arcade.software/scripts/1324ffc5648df698b643fdf3f0fbed7f.js'

const prodApiDomain = 't.arcade.show'
const localApiDomain = 'arcade-analytics.ngrok.io'
const isLocal = !isProductionEnv() && !isPreviewEnv()
const isLocalPipelineActive = async () => {
  try {
    await fetch(`https://${localApiDomain}/api/health`)
    return true
  } catch (e) {
    return false
  }
}

const settingsForSegmentPipeline = {
  writeKey: 'leOFmLAAdz4CbUYJmkrPscwVN1us2a9z',
}
const settingsForArcadePipeline = {
  writeKey: '',
  cdnSettings: {
    integrations: {
      'Segment.io': {
        // https://github.com/segmentio/analytics-next/blob/45cfa210f1367b229adbebe83d7e1c889a973386/src/plugins/segmentio/index.ts#L60
        apiHost: `${isLocal ? localApiDomain : prodApiDomain}/api/ingest`,
      },
    },
  },
}

class SegmentLoader {
  AnalyticsBrowser: Promise<typeof AnalyticsBrowserClass>
  analytics: Promise<Analytics>
  resolver: (analytics: Analytics) => void
  didInit: boolean = false

  constructor(mock: boolean) {
    this.resolver = () => undefined

    if (mock) {
      this.analytics = new Promise<Analytics>(() => {})
      this.AnalyticsBrowser = new Promise<typeof AnalyticsBrowserClass>(
        () => {}
      )
    } else {
      this.AnalyticsBrowser = new Promise<typeof AnalyticsBrowserClass>(
        (resolve, reject) => {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.async = true
          script.onload = () => {
            const AnalyticsBrowser = (window as any)['AnalyticsNext']?.[
              'AnalyticsBrowser'
            ]
            if (AnalyticsBrowser) {
              resolve(AnalyticsBrowser)
            } else {
              reject()
            }
          }
          script.onerror = function (...args) {
            setContext('Analytics.js load error', { ...args })
            reject(new Error(`Error loading ${analyticsCdnUrl}`))
          }
          script.src = analyticsCdnUrl

          const firstScriptOnPage = document.getElementsByTagName('script')[0]
          firstScriptOnPage.parentNode?.insertBefore(script, firstScriptOnPage)
        }
      )

      this.analytics = new Promise<Analytics>(resolve => {
        this.resolver = resolve
      })
    }
  }

  init = async (useArcadePipeline: boolean) => {
    if (this.didInit) {
      return
    }

    this.didInit = true

    if (isLocal && useArcadePipeline && !(await isLocalPipelineActive())) {
      // eslint-disable-next-line no-console
      console.info(
        `No local analytics pipeline is running on ngrok. You can safely disregard the above CORS and 404 errors for https://${localApiDomain}/api/health.`
      )
      return
    }

    await this.AnalyticsBrowser.then(AnalyticsBrowser =>
      AnalyticsBrowser.load(
        useArcadePipeline
          ? settingsForArcadePipeline
          : settingsForSegmentPipeline
      )
    )
      .then(([analytics]): Analytics => analytics)
      .then(this.resolver)
  }

  identify: Analytics['identify'] = (userId, traits, options) => {
    const timestamp = new Date()
    return this.analytics.then(analytics =>
      analytics.identify(userId, traits, {
        ...options,
        timestamp,
      })
    )
  }

  page: Analytics['page'] = (category, name, properties, options) => {
    const timestamp = new Date()
    return this.analytics.then(analytics =>
      analytics.page(category, name, properties, {
        ...options,
        timestamp,
      })
    )
  }

  track: Analytics['track'] = (event, properties, options) => {
    const timestamp = new Date()
    return this.analytics.then(analytics =>
      analytics.track(event, properties, {
        ...options,
        timestamp,
      })
    )
  }

  trackLink: Analytics['trackLink'] = (links, event, properties, options) => {
    const timestamp = new Date()
    return this.analytics.then(analytics =>
      analytics.trackLink(links, event, properties, {
        ...options,
        timestamp,
      })
    )
  }
}

const segment = new SegmentLoader(typeof window === 'undefined')

class EventTracker<PN extends PageName> {
  private user: User | null
  private readonly pageName: PN
  private pageProperties: PageProperties<PN>
  private readonly shouldPublishToHost: boolean
  private readonly options: Options
  private readonly useArcadePipeline: boolean

  constructor(
    pageName: PN,
    pageProperties: PageProperties<PN>,
    options?: PageTrackerOptions
  ) {
    const {
      shouldPublishToHost = false,
      shouldAnonymizeIP = false,
      doNotTrack = false,
      useArcadePipeline = true,
    } = options ?? {}

    this.user = null
    this.pageName = pageName
    this.pageProperties = pageProperties
    this.shouldPublishToHost = shouldPublishToHost
    this.useArcadePipeline = useArcadePipeline
    this.options = {
      ...(shouldAnonymizeIP ? { context: { ip: '0.0.0.0' } } : {}),
    }

    if (!doNotTrack) {
      segment.init(useArcadePipeline)
    }
  }

  // Used in conjunction with `doNotTrack`. This allows us to enable tracking
  // only after a user consents to cookies.
  enableTracking() {
    segment.init(this.useArcadePipeline)
  }

  setPageProperties(pageProperties: PageProperties<PN>) {
    this.pageProperties = pageProperties
  }

  setUser(newUser: User) {
    if (!newUser.id) return

    if (this.user === null || this.user.id !== newUser.id) {
      this.user = newUser

      segment.identify(
        newUser.id,
        {
          email: newUser.email,
        },
        this.options
      )
    }
  }

  page() {
    segment.page(undefined, this.pageName, this.pageProperties, this.options)

    if (this.shouldPublishToHost && this.pageName === 'Viewer') {
      publishToHost(
        'Viewer',
        'Flow Rendered',
        this.pageProperties as PageProperties<'Viewer'>,
        {}
      )
    }
  }

  report<EventName extends keyof PageEvents<PN>>(
    eventName: EventName,
    eventProperties: PageEvents<PN>[EventName]
  ) {
    segment.track(
      eventName as string,
      {
        ...this.pageProperties,
        ...eventProperties,
        name: this.pageName,
      },
      this.options
    )
    if (this.shouldPublishToHost) {
      publishToHost(
        this.pageName,
        eventName,
        this.pageProperties,
        eventProperties
      )
    }
  }

  attachLink<EventName extends keyof PageEvents<PN>>(
    anchorElement: HTMLAnchorElement,
    eventName: EventName,
    eventProperties: PageEvents<PN>[EventName]
  ) {
    segment.trackLink(
      anchorElement,
      eventName as string,
      {
        ...this.pageProperties,
        ...eventProperties,
      } as SegmentEvent['properties'],
      this.options
    )
  }
}

export default EventTracker
